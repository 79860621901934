<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link
        class="brand-logo"
        style="display: block"
      >
        <b-img
          v-if="checkCoca()"
          fluid
          src="@/assets/images/logo/coca_logo.png"
          alt="Logo"
          style="width: 15%"
        />
        <b-img
          v-else-if="checkOutHome()"
          fluid
          src="@/assets/images/logo/ooh.avif"
          alt="Logo"
          style="width: 7%"
        />
        <b-img
          v-else
          fluid
          class="logo-bm d-none d-lg-block"
          src="@/assets/images/logo/Logo_BrandMe_Sponsorship.avif"
          alt="Logo"
        />
      </b-link>
      <!-- /Brand logo-->
      <!-- Left Text-->
      <!-- TODO: Backgroud para outofhome -->
      <b-col
        lg="6"
        class="d-none d-lg-flex align-items-center"
        style="background-size: cover"
        :style="bgurl"
      >
       <div class="w-100 h-100 d-lg-flex align-items-center justify-content-center">
          <video class="video-bg" preload="auto" loop="" autoplay="" muted="" playsinline="" v-if="checkOutHome()">
            <source src="https://fintual.outofhome.mx/wp-content/uploads/2022/02/out-of-home-portafolio.mp4" type="video/webm">
          </video>
          <b-link v-else href="https://www.instagram.com/gabi_graf/" target="_blank">
            <div class="avatar-position d-flex align-items-center">
              <b-avatar
                @click="'https://www.instagram.com/gabi_graf/'"
                src="@/assets/images/logo/avatar_gabi.avif"
                class="avatar-login"
                size="4em"
              />
              <span class="username-logo">Gabi Graf</span>
            </div>
          </b-link>
        </div>
      </b-col>
      <!-- /Left Text-->
      <!-- Register-->
      <b-col
        lg="6"
        class="d-flex align-items-center auth-bg px-2 p-0"
      >
        <b-col
          class="col-12 col-md-7 px-xl-2 mx-auto"
        >
          <div v-show="!form">
            <b-card-title 
              class="mb-1 font-weight-bold text-center avenir-heavy font-32 text-black"
              title-tag="h2">
             {{$t('register.starts')}}
            </b-card-title>
            <b-card-text class="mb-2 text-center avenir-medium" v-if="!checkOutHome()">
                {{$t('register.access')}}
            </b-card-text>
            <div class="auth-footer-btn d-flex justify-content-center" v-if="!checkOutHome()">
              <!--<b-button
                variant="facebook"
                href="javascript:void(0)"
              >
                <feather-icon icon="FacebookIcon" />
              </b-button> -->
              <GoogleLogin  class="google-btn" :params="params" :onSuccess="onSuccess" :onFailure="onFailure">
              <span class="google-icon" :style="`background: url(${require('@/assets/images/google/google.svg')}) transparent 5px 50% no-repeat;`"></span>
              <span class="google-text">{{$t('register.google')}}</span>
            </GoogleLogin>
            </div>

            <!-- divider -->
            <div class="divider my-2" v-if="!checkOutHome()">
              <div class="divider-text">
                  {{$t('register.or')}}
              </div>
            </div>

            <!-- form -->
            <validation-observer
              ref="registerForm"
              #default="{invalid}"
            >
              <b-form
                class="auth-register-form mt-2"
                @submit.prevent="register"
              >
                <b-row>
                  <b-col
                    lg="6"
                  >
                    <!-- firstname -->
                       <b-form-group>
                        <label for="login-name" class="avenir-medium">{{ $t('register.name') }}</label>
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('register.name')"
                        :vid="$t('register.name')"
                        rules="required"
                      >
                        <b-form-input
                          id="register-username"
                          v-model="newUser.first_name"
                          name="register-username"
                          :state="errors.length > 0 ? false:null"
                          :placeholder="$t('register.name')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    lg="6"
                  >
                  <!-- lastname -->
                   <b-form-group>
                    <label for="login-lastname" class="avenir-medium">{{ $t('register.lastname') }}</label>
                      <validation-provider
                        #default="{ errors }"
                       :name="$t('register.lastname')"
                       :vid="$t('register.lastname')"
                       rules="required"
                      >
                        <b-form-input
                          id="register-lastname"
                          v-model="newUser.last_name"
                          name="register-lastname"
                          :state="errors.length > 0 ? false:null"
                          :placeholder="$t('register.lastname')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>

                <!-- email -->
              <b-form-group>
                <label for="login-email" class="avenir-medium">{{ $t('register.email') }}</label>
                  <validation-provider
                    #default="{ errors }"
                  :name="$t('register.email')"
                  :vid="$t('register.email')"
                  rules="required|email"
                  >
                    <b-form-input
                      id="register-email"
                      v-model="newUser.email"
                      name="register-email"
                      :state="errors.length > 0 ? false:null"
                      :placeholder="$t('register.email')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- password -->
                  <b-form-group>
                  <label for="login-password" class="avenir-medium">{{ $t('register.password') }}</label>
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('register.password')"
                    :vid="$t('register.password')"
                    rules="required"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid':null"
                    >
                      <b-form-input
                        id="register-password"
                        v-model="newUser.password"
                        class="form-control-merge"
                        :type="passwordFieldType"
                        :state="errors.length > 0 ? false:null"
                        name="register-password"
                        placeholder="············"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          :icon="passwordToggleIcon"
                          class="cursor-pointer"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group>
          
                  <p class="avenir-medium"> {{$t('register.checkIn')}}<b-link href="https://brandme.la/legal/" target="_blank">{{$t('register.terms')}}  </b-link></p>

                </b-form-group>

                <b-button
                  variant="blue-button"
                  class="blue-button"
                  block
                  type="submit"
                  :disabled="invalid"
                >
                    {{$t('register.create')}}
                </b-button>
              </b-form>
            </validation-observer>

            <p class="text-center mt-2 avenir-medium">
              <span>  {{$t('register.account')}}</span>
              <b-link :to="{name:'auth-login'}">
                <span>&nbsp;{{$t('register.login')}}</span>
              </b-link>
            </p>
          </div>
          <div v-show="form">
            <b-card-title class="mb-1">
             {{$t('register.text')}}
            </b-card-title>
            <b-card-text class="mb-2">
               {{$t('register.text1')}}
            </b-card-text>
            <b-row>
              <b-col
                md="6"
                lg="6"
              >
                <b-card
                  :img-src="require('@/assets/images/slider/04.jpg')"
                  img-alt="Card image cap"
                  img-top
                  title="Anunciante"
                >

                  <b-card-text>
                     {{$t('register.text2')}}
                  </b-card-text>
                </b-card>
              </b-col>
              <b-col
                md="6"
                lg="6"
              >
                <b-card
                  :img-src="require('@/assets/images/slider/04.jpg')"
                  img-alt="Card image cap"
                  img-top
                  title="Card title"
                >

                  <b-card-text>
                     {{$t('register.text3')}}
                  </b-card-text>
                  <b-button
                    variant="outline-primary"
                  >
                    {{$t('register.text4')}}
                  </b-button>
                </b-card>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-col>
    <!-- /Register-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import {
  BCard, BRow, BCol, BLink, BButton, BForm, BFormGroup, BFormInput, BInputGroup,
  BInputGroupAppend, BImg, BCardTitle, BCardText, BAvatar
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import useJwt from '@/auth/jwt/useJwt'
import GoogleLogin from 'vue-google-login'
import abilities from '@/libs/acl/abilities'
import service from '@/services/others'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import utils from '@/libs/utils'

export default {
  components: {
    BAvatar,
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    // validations
    ValidationProvider,
    ValidationObserver,
    BCard,
    GoogleLogin
  },
  mixins: [togglePasswordVisibility],
  data () {
    return {
      newUser: {
        status: '',
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        is_outofhome: false
      },
      sideImg: require('@/assets/images/pages/register-v2.svg'),
      bgurl: this.backRegister(window.location.host),
      rutaDominio: window.location.host,
      // validation
      required,
      email,
      readAbility: [],
      form: false,
      params: {
        client_id: '41988944628-epj92qelccpcub5snlh6p08l4mnelmql.apps.googleusercontent.com'
      },
      errorPhone: false
    }
  },
  computed: {
    passwordToggleIcon () {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl () {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/register-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    }
  },
  created () {
    this.backRegister(this.rutaDominio)
    localize('es')
  },
  methods: {
    register () {
      this.$refs.registerForm.validate().then(success => {
        if (success) {
          if (this.checkOutHome()) {
            this.newUser.is_outofhome = true;
          }

          useJwt.register(this.newUser)
            .then(response => response.data.response)
            .then(response => {
              this.setUser(response)
            })
            .catch(error => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: this.$t('register.errorRegister'),
                  icon: 'AlertCircleIcon',
                  variant: 'danger',
                  text: error.response.data.response.message ? this.$t('register.emailExists') : ''
                }
              })
            })
        }
      })
    },
    backRegister (ruta) {
      if (ruta.includes('coca')) return `background-image: url(${require("@/assets/images/coca1.png")})`
      return `background-image: url(${require("@/assets/images/logo/gabi_logo.avif")})`
    },
    onSuccess (googleUser) {
      const keys = Object.keys(googleUser)
      let index = ''
      keys.forEach(key => {
        if (googleUser[key].id_token) index = googleUser[key]
      })
      service.googleLogin({token: index.id_token})
        .then(response => {
          this.setUser(response)
        })
    },
    onFailure () {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Error de Registro',
          icon: 'AlertCircleIcon',
          text: 'No se pudo registrar con google',
          variant: 'warning'
        }
      },
      {
        position: 'top-left'
      })
    },
    setUser (data) {
      utils.setPermissions(data.user);
      const abilitie = abilities.getAbilitie(data.user)
      useJwt.setToken(data.access_token)
      useJwt.setRefreshToken(data.refresh_token)
      utils.setuserData(data, abilitie)
      this.$ability.update(abilitie)
      this.$store.commit('appConfig/UPDATE_IS_VERIFIED', data.user.is_verified)
      const route = getHomeRouteForLoggedInUser(data.user)
      this.$router.push(route)
    },
    getRoute (user) {
      if (user.main_group === null) return '/roll'
      return '/dashboard'
    },
    checkCoca() {
      return this.rutaDominio.includes('coca')
    },
    checkOutHome() {
      return this.rutaDominio.includes('outofhome')
    }
  }
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '~@core/scss/base/pages/app-ecommerce.scss';
@import '~vue-form-wizard/dist/vue-form-wizard.min.css';
</style>

<style lang="scss" scoped>
.checkout-form-wizard ::v-deep {
  .wizard-tab-content {
    box-shadow: none !important;
    background: transparent !important;
    padding: 0;
  }
}
.auth-register-form {
  label {
    font-size: 14px
  }
}
</style>
